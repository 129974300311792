<template>
  <v-container>
    <v-row class="pt-md-6">
      <v-col>
        <table class="overview">
          <tr v-if="user.first_name || user.last_name">
            <th>{{ $t("label.name") }}</th>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
          </tr>
          <tr>
            <th>{{ $t("label.email") }}</th>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <th>{{ $t("user.password") }}</th>
            <td>
              <a @click="showPwdDialog = true" text>{{
                $t("user.changePassword")
              }}</a>
            </td>
            <td v-if="alertText">
              <v-alert type="success" dismissible text dense>{{
                alertText
              }}</v-alert>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="user.admin_link" cols="auto">
        <v-btn :href="user.admin_link" target="_blank">
          <v-icon class="mr-1">mdi-open-in-new</v-icon>
          {{ $t("label.adminInterface") }}
        </v-btn>
      </v-col>
      <v-col v-if="can('export_complete', 'person')" cols="auto">
        <v-btn href="/api/person-rich/complete/">
          <v-icon class="mr-1">mdi-download</v-icon>
          {{ $t("label.exportComplete") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pt-md-6">
      <v-col>
        <v-btn color="secondary" @click="logout">
          <v-icon class="mr-1">mdi-logout</v-icon>
          {{ $t("user.logout") }}
        </v-btn>
      </v-col>
    </v-row>
    <PasswordChangeDialog
      v-if="showPwdDialog"
      v-model="showPwdDialog"
      @success="showPwdChangeSuccess"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PasswordChangeDialog from "@/components/account/PasswordChangeDialog";

export default {
  name: "UserPage",

  components: { PasswordChangeDialog },

  data() {
    return {
      showPwdDialog: false,
      alertText: "",
    };
  },

  computed: {
    ...mapState({
      user: "user",
    }),
    ...mapGetters({
      can: "can",
    }),
  },

  methods: {
    ...mapActions(["logout"]),
    showPwdChangeSuccess() {
      this.alertText = this.$t("user.passwordChangeSuccess");
    },
  },
};
</script>
